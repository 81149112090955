footer {
    /*    background: url(../images/footer_bg.jpg);*/
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
    }
    .footer_area[lang="false"]{
        text-align: left;
        direction: ltr;
    }
    .footer_area[lang="false"] .footer_widget .widget_menu i{
        left: 0;
        right: auto;
    }
    .footer_area[lang="false"] .footer_widget .widget_menu li {
        padding-left: 25px;
        padding-right: 0;
    }
    .footer_area[lang="false"] .footer_widget .widget_menu li.widget {
        padding-left: 0;
    }
    .footer_area[lang="false"] li:hover {
        transform: translateX(5px);
    }
    footer:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: #00152f;
        top: 0;
        z-index: -1;
        opacity: .94;
    }
    
    footer .social-list-2 {
        margin-top: 25px;
    }
    
    footer .social-list-2 li {
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
    }
    
    footer .social-list-2 li a {
    margin: 0 3px 0 0;
        font-size: 14px;
        width: 40px;
        /* align-items: center; */
        height: 40px;
        background-color: rgba(0, 0, 0, .5);
        color: #fff;
        border-radius: 3px;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
            box-shadow: 0 0 5px 0px rgba(0, 0, 0, .5);
    }
    
    .footer_area {
        padding: 35px 0;
        color: #fff;
    }
    
    .footer_area a {
        color: #fff;
        font-size: 16px;
    }
    
    .footer_logo {
        margin-bottom: 12px;
    }
    .footer_logo img {
        width: auto;
        height: 96px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(165deg) brightness(103%) contrast(101%);
    }
    .footer_contact_inf {
        margin-top: 25px;
    }
    
    .footer_contact_inf li {
        position: relative;
        margin-bottom: 15px;
        padding-right: 25px;
    }
    
    .footer_contact_inf i {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 1.9;
    }
    
    .footer_widget h4 {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    .footer_widget .widget_menu li {
        margin-bottom: 10px;
        position: relative;
        z-index: 1;
        padding-right: 25px;
    }
    
    .footer_widget .widget_menu a,
    .footer_widget .widget_menu a:hover {
        color: #fff;
    }
    
    .footer_widget .widget_menu i {
        position: absolute;
        right: 0;
        top: 0;
        line-height: 1.8;
    }
    
    .footer_area li:hover {
        transform: translatex(-5px);
    }
    
    .footer_area li {
        transition: .3s linear;
    }
    footer form {
        margin-bottom: 20px;
    }
    footer form .form-control {
        width: 80%;
        height: 40px;
        padding: 10px 15px;
        border-radius: 0;
        font-size: 13px;
        outline: 0;
        border: transparent;
    }
    
    footer .footer_content_box .btn {
        width: 20%;
        border-radius: 0;
        padding: 11px 13px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        font-size: 11px;
        color: #fff;
        font-weight: 600;
        background-color: #5a94dc;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    
    .copyright_area {
        text-align: center;
        padding: 20px 0 20px;
        color: #fff;
        border-top: 1px solid #3597E1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .copyright_area a {
        color: #fff;
        transition: .3s linear;
    }
    
    .copyright_area i {
        color: #610099;
    }
    
    .copyright_area:hover a {
        color: #610099;
    }
    .footer_area li {
        transition: .3s linear;
    }
    .footer_widget .widget_menu li.widget {
    padding-right: 0;
}
.copy-right {
    padding-left: 4px
}