 
body .image img {
    width: 100%;
    height: 260px;
    object-fit: cover;
  }
  
  @media only screen and (min-width:768px) {
    body .image img {
      height: 440px
    }
  }
  
  @media only screen and (min-width:992px) {
    body .image img {
      height: 500px
    }
  }
  
  @media only screen and (min-width:1200px) {
    body .image img {
      height: 700px;
    }
  }
  
  body .swiper-slide .image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .4), rgb(0 0 0 / 15%));
  }

body .swiper-button-next,
body .swiper-button-prev {
  top: 50% !important;
  transform: translate(0, 16%);
  z-index: 2;
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  background-image: none !important;
  margin: 0;
  border-radius: 50%;
  color: #fff !important;
  background-color: rgba(0, 0, 0, .5);
  line-height: 1;
  width: 48px;
  height: 48px;
  box-shadow: 0 0 8px rgb(0 105 119 / 65%);
  transition: all .4s ease-out;
}

@media only screen and (min-width:768px) {

  body .swiper-button-next,
  body .swiper-button-prev {
    width: 52px;
    height: 52px
  }
}

@media only screen and (min-width:992px) {

  body .swiper-button-next,
  body .swiper-button-prev {
    width: 62px;
    height: 62px;
    color: #fff;
  }
}

@media only screen and (min-width:1200px) {

  body .swiper-button-next,
  body .swiper-button-prev {
    width: 72px;
    height: 72px
  }
}

@media only screen and (min-width:1600px) {

  body .swiper-button-next,
  body .swiper-button-prev {
    width: 82px;
    height: 82px
  }
}

body .swiper-button-next span,
body .swiper-button-prev span {
  color: #fff
}

@media only screen and (min-width:992px) {

  body .swiper-button-next span,
  body .swiper-button-prev span {
    font-size: 22px
  }
}

body .swiper-button-next:hover,
body .swiper-button-prev:hover {
    transform: translate(0, 12%) scale(1.2);
    background-color: #016978;
    box-shadow: 0 2px 8px rgb(1 105 120);
}

body .swiper-button-next:focus,
body .swiper-button-prev:focus {
  outline: 0
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    font-size: 18px;
}
body .swiper-button-prev {
  left: unset !important;
  right: 8px !important
}

@media only screen and (min-width:768px) {
  body .swiper-button-prev {
    right: 12px !important
  }
}

@media only screen and (min-width:992px) {
  body .swiper-button-prev {
    right: 20px !important
  }
}

@media only screen and (min-width:1200px) {
  body .swiper-button-prev {
    right: 30px !important
  }
}

@media only screen and (min-width:1600px) {
  body .swiper-button-prev {
    right: 42px !important
  }
}

body .swiper-button-next {
  left: 8px !important;
  right: unset !important
}

@media only screen and (min-width:768px) {
  body .swiper-button-next {
    left: 12px !important
  }
}

@media only screen and (min-width:992px) {
  body .swiper-button-next {
    left: 20px !important
  }
}

@media only screen and (min-width:1200px) {
  body .swiper-button-next {
    left: 30px !important
  }
}

@media only screen and (min-width:1600px) {
  body .swiper-button-next {
    left: 42px
  }
}

body .swiper-pagination {
  position: absolute;
  width: unset !important;
  height: unset !important;
  bottom: -30px !important;
  top: unset !important;
  left: 50% !important;
  display: grid;
  grid-gap: 6px;
  background-color: rgba(0, 0, 0, .39);
  box-shadow: 0 0 4px rgba(157, 197, 10, .404);
  border-radius: 52px;
  padding: 6px 16px 32px 16px;
  grid-auto-flow: column;
  justify-content: start;
  transform: translate(-50%, 0)
}

@media only screen and (min-width:768px) {
  body .swiper-pagination {
    grid-gap: 8px
  }
}

@media only screen and (min-width:992px) {
  body .swiper-pagination {
    transform: none;
    grid-auto-flow: row;
    grid-gap: 12px;
    padding: 26px 42px 26px 12px;
    top: 73px !important;
    bottom: unset !important;
    left: unset !important;
    right: -40px !important
  }
}

body .swiper-pagination span {
  width: 22px;
  height: 22px;
  margin: 0 !important;
  position: relative;
  opacity: 1;
  background-color: transparent;
  transform: rotate(-270deg);
  color: #fff;
  font-size: 16px;
  transition: all .4s ease-out
}

@media only screen and (min-width:992px) {
  body .swiper-pagination span {
    width: 38px;
    height: 38px
  }
}

@media only screen and (min-width:768px) {
  body .swiper-pagination span {
    font-size: 18px
  }
}

@media only screen and (min-width:992px) {
  body .swiper-pagination span {
    font-size: 32px
  }
}

body .swiper-pagination span::before {
  content:'\f10c';
  font-family: 'FontAwesome';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

body .swiper-pagination span:focus {
  outline: 0
}

body .swiper-pagination span.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: transparent;
  color: #009883;
  transform: rotate(0)
}

body .swiper-pagination span.swiper-pagination-bullet-active::before {
  content: '\f10c';
  font-family: 'FontAwesome';
}

.swiper-wrapper .swiper-slide .slider-text {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0 50px;
    z-index: 1;
    right: 0;
    justify-content: right;
}
.swiper-wrapper .swiper-slide .slider-text[lang="false"]{
  right: auto;
  left: 0;
  justify-content: end;
}
.swiper-wrapper .swiper-slide .slider-text[lang="false"] .text-buttons .text>p{
  text-align: left;
}
.swiper-wrapper .swiper-slide .slider-text[lang="false"] .text-buttons .buttons{
  justify-content: end;
}
@media only screen and (min-width: 768px) {
  .swiper-wrapper .swiper-slide .slider-text {
    padding: 0 62px
  }
}

@media only screen and (min-width:992px) {
  .swiper-wrapper .swiper-slide .slider-text {
    padding: 0 132px;
    display: grid;
    align-items: center
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons {
  display: grid;
  grid-gap: 18px;
  width: 90%;
  margin: auto
}

@media only screen and (min-width: 992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons {
    grid-gap: 32px;
    width: 100%
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .text {
  display: grid;
  grid-gap: 8px
}

@media only screen and (min-width: 992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .text {
    grid-gap: 18px
  }
}

@media only screen and (min-width: 1200px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .text {
    grid-gap: 22px
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p {
  color: #fff;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  text-transform: uppercase;
  line-height: 1.3;
  margin: 0;
  text-align: right;
}
.slider-text .theme-btn-one {
  box-shadow: none;

}
.swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p:first-of-type {
  text-shadow: 0 0 17px rgba(255, 255, 255, .8);
  font-weight: bolder;
  transform: translate(0, -100px) scale(0);
  transition: all 2s cubic-bezier(.68, -.55, .265, 1.55) 1s;
  font-size: 26px
}

@media only screen and (min-width:768px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p:first-of-type {
    font-size: 32px
  }
}

@media only screen and (min-width:992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p:first-of-type {
    font-size: 48px
  }
}

@media only screen and (min-width: 1200px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p:first-of-type {
    font-size: 42px
  }
}

@media only screen and (min-width:1600px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p:first-of-type {
    font-size: 72px
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p:last-of-type {
  transform: translate(300px, 0);
  transition: all 1.8s cubic-bezier(.68, -.55, .265, 1.55) 2s;
  font-size: 14px;
  font-weight: bolder;
  margin-left: 14px;
}

@media only screen and (min-width:768px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p:last-of-type {
    font-size: 16px
  }
}

@media only screen and (min-width:992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .text>p:last-of-type {
    font-size: 18px
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons {
  justify-self: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  width: 100%;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width:992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons {
    grid-gap: 22px
  }
}

@media only screen and (min-width:1200px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons {
    grid-gap: 32px
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a {
  border-radius: 50px;
  transform: scale(1.5);
  opacity: 0;
  visibility: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 3px;
  padding: 8px 14px;
  position: relative;
  background-color: rgb(241 134 67);
}

@media only screen and (min-width:768px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a {
    padding: 8px 16px
  }
}

@media only screen and (min-width:992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a {
    grid-gap: 4px;
    padding: 10px 18px
  }
}

@media only screen and (min-width:1200px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a {
    padding: 14px 24px;
    grid-gap: 5px
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a i,
.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a span {
  color: #fff;
  position: relative;
  z-index: 1
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a span {
  font-size: 12px
}

@media only screen and (min-width:768px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a span {
    font-size: 16px
  }
}

@media only screen and (min-width:992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a span {
    font-size: 18px
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a i {
  font-size: 16px
}

@media only screen and (min-width:768px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a i {
    font-size: 18px
  }
}

@media only screen and (min-width:992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a i {
    font-size: 22px
  }
}

@media only screen and (min-width:1200px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a i {
    font-size: 26px
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a:first-of-type {
  transition: transform .4s ease-out 3s, scale .4s ease-out 3s, opacity .4s ease-out 3s, visibility .4s ease-out 3s, background-color .3s ease-out;
  background-color: rgb(0 105 119)
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a:first-of-type:hover {
  background-color: rgb(0 105 119);
  color: #fff;
  border: 0;
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a:last-of-type {
  overflow: hidden;
  transition: all .4s ease-out 3.5s
}

@media only screen and (min-width:992px) {
  .swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a:last-of-type {
    display: grid
  }
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a:last-of-type::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 1px;
  border-radius: 50%;
  transition: all .6s ease-out
}

.swiper-wrapper .swiper-slide .slider-text .text-buttons .buttons a:hover::after {
  box-shadow: 0 0 0 5rem #006977d7;
  z-index: -1;
}

.swiper-wrapper .swiper-slide.swiper-slide-active .slider-text .text-buttons .text p {
  transform: translate(0, 0) scale(1);
  opacity: 1;
  visibility: visible
}

.swiper-wrapper .swiper-slide.swiper-slide-active .slider-text .text-buttons .buttons a {
  opacity: 1;
  visibility: visible;
  transform: scale(1)
}

@keyframes moreLoopY {
  0% {
    transform: scale(1, 0);
    transform-origin: top
  }

  47% {
    transform: scale(1, 1);
    transform-origin: top
  }

  48% {
    transform: scale(1, 1);
    transform-origin: bottom
  }

  95% {
    transform: scale(1, 0);
    transform-origin: bottom
  }

  96% {
    transform: scale(1, 0);
    transform-origin: top
  }

  100% {
    transform: scale(1, 0)
  }
}
