@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Tajawal', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f3f8;
  direction: rtl;
}
*[lan="en"] {
  font-family: 'Poppins', sans-serif;
}
section[lang=false]{
  direction: ltr;
  text-align: left !important;
  font-family: 'Poppins', sans-serif;
}
p {
  font-size: 16px;
  line-height: 1.9;
}
li {
  list-style: none;
}
dl, ol, ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
a {
  color: unset;
  text-decoration: none !important;
}
section[lang=false] .sec-title h2 {
  font-family: 'Poppins', sans-serif;
}
.sec-title h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 1.2em;
  text-align: center;
  color: #222222;
  font-weight: 600;
  padding-bottom: 25px;
  text-transform: capitalize;
}
.sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 55px;
}
.sec-title.centered .separator {
  margin: 0 auto;
}

.sec-title .separator {
  position: relative;
  width: 100px;
}
.sec-title .separator:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1px;
  background-color: #cccccc;
}
.sec-title .separator:after {
  position: absolute;
  content: '';
  left: 50%;
  top: -15px;
  width: 30px;
  height: 30px;
  margin-left: -15px;
  border: 10px solid #009682;
  background-color: #ffffff;
  border-radius: 50%;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.lg-container.lg-show {
  display: block;
  direction: ltr;
}
/* padding: 70px 0; */
.mfa-gallery {
  padding: 70px 0;
}
.department-section {
  padding: 70px 0 !important;
}
.about-slide {
  padding: 70px 0;
  /* padding-bottom: 0; */
}

@media only screen and (min-width: 0px) and (max-width: 767px){
.sec-title h2 {
    font-size: 20px;
}
}
.theme-btn-one {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  color: #fff!important;
  text-align: center;
  padding: 17px 40px;
  border-radius: 30px;
  margin-bottom: 22px;
  z-index: 1;
  box-shadow: 0 20px 30px #d5edea;
  transition: .5s;
  background-color: #006977;
}
.theme-btn-one:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 30px;
  right: 0;
  top: 0;
  z-index: -1;
  transform: scale(0,0);
  transition: .5s;
  background: #1d65d7;
}
.theme-btn-one:hover {
  background: #1d65d7;
}
.theme-btn-one:hover:before {
  transform: scale(1,1);
}
.bg-green {
  background-color: #25d366;
}
.bg-blue {
  background-color: #00acee;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, .5)
  }

  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0)
  }
}

.resale {
  padding-bottom: 40px
}

.btn-facebook-pulse {
  background-color: #3b5898;
  bottom: 127px;
  align-items: center;
}

.btn-facebook-pulse,
.btn-facebook-pulse-2 {
  justify-content: center;
  width: 5px;
  height: 2px;
  color: #fff;
  position: fixed;
  left: 9px;
  font-size: 28px;
  display: flex;
  padding: 24px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 4;
}

.btn-phone-pulse {
  background: #00acee;
  color: #fff;
  position: fixed;
  bottom: 300px;
  left: 9px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 24px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 4;
}

.btn-facebook-pulse {
  background-color: #3b5898;
  bottom: 127px;
  align-items: center;
}

.btn-instagram-pulse2 {
  background-color: #c13584;
  bottom: 71px;
  align-items: center;
}

.btn-instagram-pulse2,
.btn-youtube-pulse {
  color: #fff;
  position: fixed;
  left: 9px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  width: 0;
  height: 0;
}

.btn-whatsapp-pulse {
  background: #25d366;
  color: #fff;
  position: fixed;
  bottom: 242px;
  left: 9px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
}
.btn-tiktok-pulse {
  background: #000;
  color: #fff;
  position: fixed;
  bottom: 183px;
  left: 9px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 4;
}
.btn-instagram-pulse2,
.btn-whatsapp-pulse {
  padding: 24px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 4;
}
.Social-icon a {
  transition: 0.5s all ease;
}
.Social-icon a:hover {
  color: #237d89
}
.btn-youtube-pulse {
  color: #fff;
    position: fixed;
    left: 9px;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    bottom: 16px;
    height: 50px;
    /* padding: 24px; */
    border-radius: 50px;
    background-color: #f00;
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    z-index: 4;
}

  #main{
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
  }

  .fof{
    display: table-cell;
    vertical-align: middle;
  }

  .fof h1{
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
  }

  @keyframes type{
    from{box-shadow: inset -3px 0px 0px #888;}
    to{box-shadow: inset -3px 0px 0px transparent;}
  }
