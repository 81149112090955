
/*************department-section *************/

.department-section {
    position: relative;
    padding: 40px 0;
    /* margin-top: -24px; */
}

.department-section .department-box {
    position: relative;
    margin-bottom: 30px;
}
.department-section .department-box .inner-box {
    position: relative;
}
.department-section .department-box .inner-box .image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 20px 40px rgba(0,0,0,0.10);
    box-shadow: 0 0 27px #999999ad;
}
.department-section .department-box .inner-box .image img {
    position: relative;
    width: 100%;
    height: 330px;
    object-fit: cover;
    display: block;
}
.department-section .department-box .inner-box .lower-content {
    position: relative;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: -62px;
    border-radius: 10px;
    padding-top: 30px;
    text-align: center;
    overflow: hidden;
background-color: #f8f9fa;
}
.department-section .department-box .inner-box .lower-content h3 {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    color: #222222;
    line-height: 1.3em;
    padding-left: 15px;
    padding-right: 15px;
}
.department-section .department-box .inner-box .lower-content h3 i{
    margin-left: 10px;
    transform: rotate(45deg);
    color: #009682;
    text-shadow: 12px 10px 5px #ddd;
}
.department-section .department-box .inner-box .lower-content h3 i:last-child{
    margin-right: 10px;
    transform: rotate(-45deg);
    color: #5a93db;
    text-shadow: -12px 10px 5px #ddd;
}
.department-section .department-box .inner-box .lower-content h3 a {
    position: relative;
    color: #222222;
}
.department-section .department-box .inner-box .lower-content .text {
    position: relative;
    color: #777777;
    font-size: 16px;
    line-height: 1.8em;
    margin-top: 22px;
    margin-bottom: 22px;
    padding-left: 20px;
    padding-right: 20px;
        overflow: hidden;
    height: 110px;
}
.department-section .department-box .inner-box .lower-content .read-more {position: relative;display: block;padding: 15px 15px;direction: rtl;text-align: center;color: #fff;font-size: 16px;font-weight: 700;background-color: #027465;/* font-family: 'Lora', serif; */transition: all 300ms ease;-webkit-transition: all 300ms ease;-ms-transition: all 300ms ease;-o-transition: all 300ms ease;-moz-transition: all 300ms ease;}
.department-section .department-box .inner-box:hover .lower-content .read-more {
	background-color:#222222;
	color:#ffffff;
}

.department-section .department-box .inner-box .lower-content span{
color: #fff;
padding-right: 7px
}