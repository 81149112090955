header {
    direction: rtl;
    transition: 0.5s all linear;
}

header[lan="en"] {
    direction: ltr;
}
.main-header[lan="en"] .elementskit-navbar-nav>li i{
    right: -17px;
    left: auto;
}
.main-header[lan="en"] .elementskit-navbar-nav>li{
    margin-left: 17px;
}
.navbar-brand img {
    width: 200px;
    height: 90px;
    object-fit: contain;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: #009682;
}

.navbar-expand-lg .navbar-nav .nav-link {
    position: relative;
    display: block;
    line-height: 35px;
    font-weight: 700;
    opacity: 1;
    color: #000;
    text-transform: capitalize;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.navbar-expand-lg .navbar-nav>li {
    margin-right: 20px;
    position: relative;
}

.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
    outline: 0;
    border: 0;
}

.navbar-toggler:focus {
    outline: 0;
    border: 0;
    box-shadow: none
}

@media (max-width: 992px) {
    .navbar-collapse {
        position: fixed;
        background-color: #fff;
        top: 0;
        right: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        width: 75%;
        height: 100%;
        z-index: 9;
        box-shadow: -200px 0px 0px 0 #00000063;
        overflow: scroll;
    }
    header[lan="en"] {
        direction: ltr;
    }
    header[lan="en"] .navbar-collapse {
        top: 0;
        left: 0;
        right: auto;
    }
    header[lan="en"] .navbar-collapse .navbar-nav {
        padding-right: 0;
        padding-left: 10px;
    }
    .navbar-collapse .navbar-nav {
        padding: 76px;
        padding-right: 10px;
        padding-left: 0;
    }
    header[lan="en"] .navbar-collapse.show .navbar-nav .nav-link{
        text-align: left;

    }
    .navbar-collapse.show .navbar-nav .nav-link {
        padding: 13px;
        text-align: right;
    }

    .nav-item.menu:before {
        left: 29px !important
    }

    .navbar-brand img {
        width: 100px;
    }

    .nav-item.menu .dropdown-menus {
        display: block !important;
        background-color: transparent !important;
    }

    .navbar-collapse.collapsing {
        right: -75%;
        transition: height 0s ease;
    }

    .navbar-collapse.show {
        right: 0;
        transition: right 300ms ease-in-out;
    }

    .navbar-toggler.collapsed~.navbar-collapse {
        transition: right 500ms ease-in-out;
    }
}

.main-header .elementskit-navbar-nav>li {
    margin-right: 17px;
    padding: 25px 0px 0px;
}


@media only screen and (max-width: 1023px) {
    .main-header .elementskit-navbar-nav > li {
        margin: 0px;
        padding: 0px 15px;
    }
    .main-header .elementskit-menu-container {
        position: fixed;
        float: none;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .main-header .elementskit-navbar-nav>li i {
        position: absolute;
        left: 15px !important;
        top: 20px !important;
        right: unset;
    }
}

.main-header .elementskit-navbar-nav>li i {
    position: absolute;
    left: -17px;
    top: 42px;
}

.elementskit-dropdown-has {
    margin-left: 16px;
}

.main-header .elementskit-dropdown {
    background-color: #ffffff;
    border-radius: 10px;
    /* border: 3px solid #009682; */
}

.main-header .elementskit-submenu-panel>li {
    position: relative;
    /*	border-bottom:1px solid #f9f9f9;*/
    display: flex;
}

.main-header .elementskit-submenu-panel li ul li:last-child {
    border-bottom: none;
}

.main-header .elementskit-submenu-panel>li>a {
    position: relative;
    padding: 12px 15px 12px 0;
    color: #31529d;
    display: block;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    background: none;
}

.main-header .elementskit-submenu-panel>li>a:hover {
    color: #009682;
}


.main-header .elementskit-dropdown li:hover::before {
    width: 100%;
}

.main-header .elementskit-dropdown li:before {
    position: absolute;
    content: '';
    right: 0px;
    top: 0px;
    width: 0px;
    height: 100%;
    /* background-color: #0bc3d5; */
    transition: all 600ms ease;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    /*    border-bu: 1px solid red;*/
    border-bottom: 2px solid #009682;
}

@media (max-width: 991px) {
    .elementskit-dropdown {
        max-height: 0;
        overflow: hidden;
        display: block;
        border: 0px;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
        -o-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
        transition: max-height 2s cubic-bezier(0, 1, 0, 1);
    }
    .elementskit-navbar-nav {
        padding-top: 100px;
        margin-right: 0;
    }
    .elementskit-dropdown.elementskit-dropdown-open {
        max-height: 2500px;
        opacity: 1;
        visibility: visible;
        -webkit-transition: max-height 5s ease-out;
        -o-transition: max-height 5s ease-out;
        transition: max-height 5s ease-out;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .main-header .elementskit-dropdown {
        background-color: #ffffff;
        border: none;
        padding: 5px;
    }
}

.elementskit-dropdown {
    transition: all 600ms ease;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
}

@media (min-width: 992px) {
    .elementskit-dropdown {
        -webkit-box-shadow: 0px 10px 30px 0px rgb(45 45 45 / 20%);
        box-shadow: 0px 10px 30px 0px rgb(45 45 45 / 20%);
        position: absolute;
        top: 100%;
        right: -57px;
        -webkit-transform:rotateX(-90deg);
        -ms-transform:rotateX(-90deg);
        transform:rotateX(-90deg);
        pointer-events: none;
    }
    .main-header[lan="en"] .elementskit-dropdown{
        left: -57px;
        right: auto;
    }
    .main-header .elementskit-navbar-nav>li{
        margin: 0;
    }
    .main-header .elementskit-navbar-nav>li.elementskit-dropdown-has{
        margin-left: 14px;
    }
    .navbar-expand-lg .navbar-nav .nav-link{
        font-size: 13px;
    }
    .main-header .elementskit-navbar-nav>li i{
        left: -7px;
        font-size: 11px;
        top: 44px;
    }
    .main-header[lan="en"] .elementskit-navbar-nav>li i{
        right: -7px;
        font-size: 11px;
        top: 44px;
    }
    .elementskit-dropdown-has:hover>.elementskit-dropdown {
        opacity: 1;
        visibility: visible;
        -webkit-transform: rotateX(0);
        -ms-transform: rotateX(0);
        transform: rotateX(0);
        pointer-events: auto;
    }
}
.navbar{
    background: #fff;
    z-index: 6;
}
.navbar-expand-lg .navbar-nav .nav-link{
    font-size: 18px;
}
@-webkit-keyframes slideInDown{from{-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0);visibility:visible}to{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}}@keyframes slideInDown{from{-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0);visibility:visible}to{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}}.slideInDown{-webkit-animation-name:slideInDown;animation-name:slideInDown}
@media (min-width: 993px){
.navbar.header-fixed {
    box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
    -webkit-box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
    background: #ffffff;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}
}
@media (min-width: 992px) {
    .elementskit-dropdown {
        min-width: 250px;
    }
}

.elementskit-dropdown {
    padding-left: 0;
    list-style: none;
    opacity: 0;
    padding: 20px;
    z-index: 9;
    visibility: hidden;
}

.lang-img {
    height: 38px;
    object-fit: contain;
    margin: auto;
    text-align: center;
    width: 51px;
}
.main-header .xs-bold-menu {
    display: none;
}

.main-header .xs-bold-menu .xs-gradient-group span {
    background: #fff;
    height: 2px;
    display: block;
    margin-bottom: 3px;
    width: 25px;
    margin-right: 8px;
}
.main-header .xs-bold-menu span {
    color: #fff;
}
.main-header .xs-bold-menu .xs-gradient-group span:nth-child(2) {
    width: 15px;
}
@media (max-width: 991px){
.elementskit-menu-container {
    background: #f7f7f7;
    background: -webkit-gradient(linear, left bottom, left top, from(#f7f7f7), to(#f7f7f7));
    background: -webkit-linear-gradient(bottom, #f7f7f7 0%, #f7f7f7 100%);
    background: -o-linear-gradient(bottom, #f7f7f7 0%, #f7f7f7 100%);
    background: linear-gradient(0deg, #f7f7f7 0%, #f7f7f7 100%);
    max-width: 350px;
    width: 80%;
    position: fixed;
    top: 0;
    right: -460px;
    height: 100%;
    -webkit-box-shadow: 0px 10px 30px 0px rgb(255 165 0 / 0%);
    box-shadow: 0px 10px 30px 0px rgb(255 165 0 / 0%);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    -webkit-transition: right 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 0ms;
    -o-transition: right 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 0ms;
    transition: right 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 0ms;
}
header[lan="en"] .elementskit-menu-container{
    left: -460px;
    right: auto;
    transition: left 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 0ms;
}
header[lan="en"] .elementskit-menu-container.active{
    left: 0;
    right: auto;
}

header[lan="en"] .elementskit-menu-overlay{
    left: -110%;
    right: auto;
}
header[lan="en"] .elementskit-menu-overlay.active{
    left: 0%;
    right: auto;
}
.elementskit-menu-container.active {
    right: 0;
}
.main-header .xs-bold-menu {
    background: linear-gradient( 135deg, rgb(90, 148, 218) 0%, rgb(48, 86, 181) 100%);
    border-radius: 5px;
    border: transparent;
    width: 50px;
    display: flex;
    position: absolute;
    left: 0;
    margin-top: 34px;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    height: 45px;
    top: 0;
    justify-content: center;
}
header[lan="en"] .xs-bold-menu {
    left: auto;
    right: 0;
    margin-right: 20px;
    margin-left: 0;
}
.elementskit-nav-identity-panel {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
}
header[lan="en"]  .elementskit-nav-identity-panel{
    right: 0;
    left: auto;
}
.main-header[lan="en"] .elementskit-navbar-nav>li i{
    right: 17px;
    left: auto !important;
}
.elementskit-menu-overlay {
    display: block;
    position: fixed;
    z-index: 5;
    top: 0;
    right: -110%;
    height: 100%;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.5);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 400ms;
    -o-transition: all 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 400ms;
    transition: all 600ms cubic-bezier(0.6, 0.1, 0.68, 0.53) 400ms;
}
.elementskit-menu-overlay.active {
    right: 0%;
    -webkit-transition: all 600ms cubic-bezier(0.22, 0.61, 0.24, 1) 0ms;
    -o-transition: all 600ms cubic-bezier(0.22, 0.61, 0.24, 1) 0ms;
    transition: all 600ms cubic-bezier(0.22, 0.61, 0.24, 1) 0ms;
}
.elementskit-nav-identity-panel .elementskit-menu-close {
    float: right;
}
}
.elementskit-menu-container {
    z-index: 10000;
}
.main-header .elementskit-nav-identity-panel .elementskit-menu-close {
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    border-width: 0;
    width: 50px;
    height: 50px;
    background-color: #006977;
    outline: none;
    margin: 20px;
    float: left;
}

.elementskit-nav-identity-panel {
    display: none;
}
.nav-item .theme-btn-one {
    font-size: 21px;
    padding: 11px 30px;
}
.nav-item.dropdown .dropdown-menu.show {
    display: grid;
    transition: 0.5s all ease;
    transform: scale(1);
}
.nav-item.dropdown .dropdown-menu {
    transition: 0.5s all ease;
    transform: scale(0);
}
.nav-item.dropdown .dropdown-menu button {
    outline: 0;
    outline: none;
    border: 0;
    padding: 9px;
    background-color: transparent;
}