.vedios {
    margin-top: 60px;
    overflow: hidden;
    padding-bottom: 14px;
}
.vedios iframe {
	border: 2px solid green;
	/* margin: 25px; */
	width: 100%;
    border-radius: 10px;
}
.vedios h1 {
    padding-bottom: 30px;
}
