/*********blog-details*********/
.blog-area .blog_full_content img {
    margin-bottom: 25px;
        width: 100%;
        height: auto;
    border-radius: 15px;
}
.blog-area .blog_full_content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.blog-area .blog_full_content .blog_meta {
    margin-bottom: 30px;
}
.blog-area .blog_full_content .blog_meta a {
    color: #565656;
    margin-left: 35px;
}
.blog-area .blog_full_content .blog_quote {
    background: #EDF7FF;
    padding: 25px 70px 20px 60px;
    position: relative;
}
.blog-area .tag_and_social_bar {
    padding: 70px 0;
}
.blog-area .comment_area h4 {
    margin-bottom: 70px;
    font-size: 20px;
    font-weight: 700;
}
.blog-area .comment_area .comment_wraper {
    margin-bottom: 105px;
}
.blog-area .comment_area .comment_wraper .comment_content {
    position: relative;
    padding-right: 135px;
    margin-bottom: 60px;
}
.blog-area .comment_area .comment_wraper .single_comment .user_photo {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    /* z-index: 2; */
}
.blog-area .comment_area .comment_wraper .single_comment>.single_comment {
    margin-right: 70px;
}
.blog-area .comment_area .comment_wraper .user_details {
    margin-bottom: 15px;
}
.blog-area .comment_area h4 {
    margin-bottom: 70px;
    font-size: 20px;
    font-weight: 700;
}
.blog-area .contact_form input {
    height: 58px;
}

.blog-area .contact_form input, .contact_form textarea {
    width: 100%;
    margin-bottom: 30px;
    background: #F5F5F5;
    border: 1px solid transparent;
    text-indent: 15px;
    transition: .3s linear;
}
.blog-area .custom_btn {
    color: #fff;
    background: #44A6F0;
    padding: 15px 30px;
    transition: .3s linear;
    position: relative;
    z-index: 1;
    border: none;
    overflow: hidden;
    text-align: center;
    min-width: 180px;
}
.blog-area form.widget_search_bar {
    width: 100%;
    height: 56px;
    position: relative;
    margin-bottom: 60px;
}
.blog-area .widget_search_bar input {
    width: 100%;
    height: 100%;
    border: none;
    font-weight: bold;
    background: #DFF1FF;
    text-indent: 15px;
}
.blog-area .widget_search_bar button {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: transparent;
    border: none;
    padding: 0 20px;
}
.blog-area .recent_post {
    margin-bottom: 30px;
}
.blog-area .recent_post>ul {
    border: none;
    margin-bottom: 30px;
    border-bottom: 1px solid #F4F4F4;
}
.blog-area .nav-tabs .nav-item {
    margin-bottom: -1px;
/*    position: relative;*/
}
.blog-area .recent_post .nav-tabs .nav-link {
/*    font-family: 'Unna', serif;*/
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
}
.blog-area .recent_post>ul>li>a {
    padding: 0 5px 15px 0;
    color: #565656!important;
    font-size: 20px;
    font-weight: 700;
    border: none!important;
    margin-right: 21px;
    position: relative;
}
.blog-area .recent_post>ul>li>a:hover:after,.blog-area .recent_post>ul>li>a.active:after {
    visibility: visible;
    opacity: 1;
}

.blog-area .recent_post>ul>li>a:after {
    position: absolute;
    content: "";
    background: #30a9de;
    width: 100%;
    height: 5px;
    border-radius: 2.5px;
    right: 0;
    transition: .3s linear;
    bottom: -2px;
    visibility: hidden;
    opacity: 0;
}

.blog-area a.single_recent_post {position: relative;margin-bottom: 20px;padding-right: 114px;color: #565656;padding: 15px 114px 15px 0px;display: inline-block;box-shadow: 0px 0px 21px 9px rgba(0, 0, 0, 0.05);border-radius: 10px;width: 100%;height: 115px;border-bottom: 2px solid #009883;}
.blog-area .widget_raper h4 {
    /* margin-top: 30px; */
    margin-bottom: 10px;
}
.blog-area .single_recent_post .rp_img {
    width: 100px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.blog-area .single_recent_post h4 {
    font-size: 15px;
    font-weight: bold;
    transition: .3s linear;
    margin-bottom: 10px;
}
.blog-area .single_recent_post span {
    display: inline-block;
}
.blog-area .widget_menu_bar {
    margin-bottom: 50px;
}
.blog-area .widget_menu_bar>h4, .widget_tag>h4, .filter_slider h5, .recent_post h5 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
    position: relative;
    margin-bottom: 30px;
    border-bottom: 1px solid #F4F4F4;
    display: block;
}
.blog-area .widget_menu_bar>h4:after, .widget_tag>h4:after, .filter_slider h5:after, .recent_post h5:after {
    position: absolute;
    content: "";
    width: 70px;
    height: 5px;
    background: #6FBAF3;
    bottom: -3px;
    right: 0;
    border-radius: 5px;
}
.blog-area .widget_menu a {
    color: #565656;
    transition: .3s linear;
    margin-bottom: 10px;
    color: #565656;
    cursor: pointer
}
.blog-area .widget_menu_bar .widget_menu i {
    padding-left: 10px;
}
.blog-area .widget_menu_bar>h4:after, .widget_tag>h4:after, .filter_slider h5:after, .recent_post h5:after {
    position: absolute;
    content: "";
    width: 70px;
    height: 5px;
    background: #6FBAF3;
    bottom: -3px;
    right: 0;
    border-radius: 5px;
}
.blog-area .widget_tag ul.tag_list {
    margin: 0 -5px;
}
.blog-area .widget_tag li {
    display: inline-block;
}
.blog-area .widget_tag a {
    color: #565656;
/*    padding: 0 10px;*/
    margin-bottom: 15px;
    border: 1px solid #CACACA;
    line-height: 50px;
    margin: 0 5px 15px;
    padding: 10px;
}
/*************gallery-page***************/

	.blog-area .share-post {
float: left;
    width: 100%;
    margin: 8px 40px 0 0;
    margin: 0;
    padding: 25px;
    border-radius: 40px;
    font-size: 18px;
            margin-bottom: 15px;
    font-style: italic;
    font-weight: 600;
    text-align: justify;
    line-height: 1.8;
    box-shadow: 0px 0px 21px 9px rgba(0, 0, 0, 0.05);
}


	.blog-area .share-post ul {
		border: 1px solid #e1e1e1;
		-webkit-border-radius: 3px;
		   -moz-border-radius: 3px;
			 -o-border-radius: 3px;
				border-radius: 3px;
	}

	.blog-area .share-post ul li {
		line-height: 40px;
		border-top: 1px solid #e1e1e1;
		padding-right: 43px;
        text-align: center;
	}

	.blog-area .share-post ul li a {
		position: relative;
	}

	.blog-area .share-post ul li a:before {
		position: absolute;
		right: -28px;
		top: -10px;
		font-family: "FontAwesome";
		font-size: 16px;
		color: #3bb8eb;
	    -webkit-transition: all 0.3s ease-in-out;
	       -moz-transition: all 0.3s ease-in-out;
	        -ms-transition: all 0.3s ease-in-out;
	         -o-transition: all 0.3s ease-in-out;
	            transition: all 0.3s ease-in-out;
	}


	.blog-area .share-post ul li.email a:before {
		content: "\f0e0";
	}

	.blog-area .share-post ul li.facebook a:before {
		content: "\f09a";
	}

	.blog-area .share-post ul li.twitter a:before {
		content: "\f099";
	}

	.blog-area .share-post ul li.instgram a:before {
		content: "\f075";
	}

	.blog-area .share-post ul li a:hover,
	.blog-area .share-post ul li a:hover:before {
		color: #ad66ac;
		text-decoration: none;
	}

	.blog-area .share-post ul li:first-child {
		border-top: none;
	}

	.blog-area .share-post ul li.count-share {
		text-align: center;
		font-size: 18px;
		padding: 29px 0 8px;
	}

	.blog-area .share-post ul li.count-share .numb {
		font-size: 48px;
		line-height: 36px;
		font-weight: 700;
	}

	.blog-area .share-post ul li a {
		font-size: 14px;
		color: #573764;
	}

	.blog-area .share-post ul span {
		display: block;
	}
    .blog-area p {
        height: auto;
        overflow: hidden;
        background-color: #fff;
        padding: 5px 10px;
        border-radius: 10px;
        border: 2px solid #f8f8f8;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #009883;
        box-shadow: 0px 0px 21px 9px rgb(0 0 0 / 5%);
        font-size: 15px;
    }
    .blog-area[lang="false"] p{
        border-left: 2px solid #009883;
        border-right: none;
    }