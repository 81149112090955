 /*================================================
Blog Area CSS
=================================================*/
.blog-area .blog-slider.owl-theme .owl-dots {
    margin-top: 0 !important;
    line-height: 0;
  }
  
  .blog-area .blog-slider.owl-theme .owl-dots .owl-dot span {
    margin: 0 5px;
    background: transparent;
    border: 2px solid #207EF7;
    border-radius: 50%;
  }
  
  .blog-area .blog-slider.owl-theme .owl-dots .owl-dot.active span {
    width: 20px;
    background-color: #207EF7;
    border-radius: 10px;
  }
  
  .blog-card {
    background-color: #F9F9F9;
    border-radius: 13px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 30px;
    overflow: hidden;
    box-shadow: 0 0 74px -48px;
  }
  
  .blog-card img {
    border-radius: 5px;
    transition: .6s;
    height: 291px;
    object-fit: fill;
  }
  
  .blog-card .b-card-text {
    padding: 30px 25px;
    z-index: 100;
    position: relative;
    color: #ffffff !important;
    transition: all 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .blog-card:hover p {
    color: #fff !important;
  }
  
  .blog-card:hover h3 a {
    color: #fff !important;
  }
  
  .blog-card .b-card-text span {
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: #555555;
    margin-bottom: 10px;
  }
  
  .blog-card .b-card-text span.t-right {
    position: absolute;
    color: #555555;
    right: 40px;
    padding-top: 2px;
  }
  
  .blog-card .b-card-text h3 {
    line-height: 1.1;
  }
  
  .blog-card .b-card-text h3 a {
    font-size: 20px;
    color: #292323;
  }
  
  .blog-card .b-card-text p {
    font-size: 15px;
    color: #000;
    direction: rtl;
  }
  
  .blog-card .b-card-text .view-more {
    padding: 18px 22px;
    margin-top: 24px;
    grid-gap: 6px;
    padding: 18px 20px;
    border-radius: 6px;
    margin-top: 22px;
  }
  
  .blog-card .b-card-text .view-more {
    justify-self: center;
    display: grid;
    /* grid-template-columns: auto auto; */
    align-items: center;
    line-height: 1;
    grid-gap: 4px;
    border-radius: 4px;
    background-color: #cecece;
    color: #575757;
    font-weight: bolder;
    padding: 24px 14px;
    margin-top: 16px;
    transition: all .3s ease-out;
  }
  
  .blog-card .b-card-text .view-more a i {
    top: 3px;
    position: relative;
    font-size: 17px;
  }
  
  .blog-card:hover {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 20px #d4d4d4;
    box-shadow: 0px 0px 20px #d4d4d4;
    /* transform: rotate(
  2deg) scale(1.1); */
    color: #fff !important;
  }
  
  .blog-card:hover .view-more {
    color: #000;
    background-color: #e0f0ff;
  }
  
  .blog-card:hover img {
    transform: rotate(1deg) scale(1.1);
  }
  
  .blog-card:hover .view-more a i {
    color: #207EF7;
  }
  
  .blog-card:hover .b-card-text:before {
    left: 1px;
  }
  
  .blog-card .b-card-text:before {
    content: "";
    position: absolute;
    left: -100%;
    top: 9px;
    width: 50%;
    height: 100%;
    background-color: #000;
    transition: all 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #000;
    -webkit-transition: all .5s;
    /* clip-path: polygon(0 0, 0% 100%, 64% 0); */
    /* clip-path: polygon(0 0, 99% 0, 110% 100%, 0% 100%); */
    transition: all .5s;
    transition: all 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: -1;
  }
  
  .blog-card:hover .b-card-text:after {
    right: 0;
  }
  
  .blog-card .b-card-text:after {
    content: "";
    position: absolute;
    right: -100%;
    /* clip-path: polygon(0 0, 0% 100%, 64% 0); */
    bottom: 0;
    top: 9px;
    width: 50%;
    height: 100%;
    background-color: #0e2224;
    -webkit-transition: all .5s;
    transition: all .5s;
    z-index: -1;
    transition: all 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .blogs_area {
    padding: 70px 0;
  }
  .blogs_area .image-column{
    direction: ltr;
  }
  .blog-area[lang="false"]{
    direction: ltr;
  }