.bg-primary {
    background: #006977 !important;
}

.ftco-section {
    padding: 7em 0;
}

.ftco-section[lang=false] {
    direction: ltr;
}

.ftco-no-pt {
    padding-top: 0;
}

.ftco-no-pb {
    padding-bottom: 0;
}

.heading-section {
    font-size: 28px;
    color: #000;
}


.form-control {
    height: 52px;
    background: #fff;
    color: #000;
    font-size: 14px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    border-radius: 10px;

}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.7) !important;
    font-weight: 500
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.7) !important;
}

.form-control:-ms-input-placeholder {
    /* IE 0+ */
    color: rgba(0, 0, 0, 0.7) !important;
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.7) !important;
}

.form-control:focus,
.form-control:active {
    border-color: rgba(0, 0, 0, 0.1);
}

textarea.form-control {
    height: inherit !important;
}

.wrapper {
    width: 100%;
    -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.contact-wrap {
    background: #fff;
}

.info-wrap[lang=false] {
    direction: ltr;
}
.info-wrap[lang=false] .dbox .icon{
    margin-right: 13px;
    margin-left: 0;
}
.info-wrap[lang=false] p span {
    float: left;
    padding-right: 3px;
    padding-left: 0;
}
.info-wrap h3 {
    color: #fff;
}

.info-wrap .dbox {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 25px;
}

.info-wrap .dbox:last-child {
    margin-bottom: 0;
}

.info-wrap .dbox p {
    margin-bottom: 0;
}

.info-wrap p span {
    font-weight: 600;
    color: #fff;
    float: right;
    padding-left: 3px;
}

.btn.theme-btn-one {
    padding: 10px 23px;
    font-size: 16px;
}

.info-wrap .dbox p a {
    color: #fff;
}

.info-wrap .dbox .icon {
    width: 50px;
    height: 50px;
    margin-left: 13px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
}

.info-wrap .dbox .icon span {
    font-size: 20px;
    color: #fff;
}

.info-wrap .dbox .text {
    width: calc(100% - 50px);
}
@media (min-width: 992px) {
    .info-wrap {
        border-radius: 5px;
        height: fit-content;
        position: sticky;
        top: 10px;
    }
    .ftco-section {
        margin-bottom: 20px;
    }
}

.form-group {
    margin-bottom: 1rem;
}


#contactForm .error {
    color: red;
    font-size: 12px;
}

#contactForm .form-control {
    font-size: 16px;
}

#message {
    resize: vertical;
    border-radius: 10px;
}

#form-message-warning,
#form-message-success {
    display: none;
}

#form-message-warning {
    color: red;
}

#form-message-success {
    color: #28a745;
    font-size: 18px;
    font-weight: bold;
}

.submitting {
    float: left;
    width: 100%;
    padding: 10px 0;
    display: none;
    font-size: 16px;
    font-weight: bold;
}
.fw-500 {
    font-weight: 600
}
.text {
    color: #fff;
    margin: 0;
    font-weight: 500;
}
.info-wrap {
    background: linear-gradient(45deg, #006977c7, #006977),url('./rectangle.png') center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    direction: rtl;
}
.sticky-top {
    z-index: 1;
}