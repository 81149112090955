.gallery_area .nav-tabs.list{
    display: -webkit-box;
}
.gallery_area button.nav-link {
    color: #565656;
    font-weight: 700;
    /* font-family: unna; */
    margin-bottom: 75px;
    font-size: 18px;
    outline: none;
    box-shadow: 0 0 27px rgb(0 0 0 / 13%);
    border: none;
    margin-left: 10px;
    padding: 5px 12px;
    background: transparent;
    transition: .3s linear;
    display: inline-block;
    margin-bottom: 30px;
}
.gallery_area button.nav-link.active.show{
    background: #009883;
    color: #fff;
}
.gallery_area {
    padding: 70px 0;
}
.tab-content>.tab-pane {
    display: none;
}
.fade:not(.show) {
    opacity: 0;
    display: none;
}