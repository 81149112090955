img{
    width: 100%;
}
.mfa-gallery .lg-react-element {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 13px;
}
.mfa-gallery .lg-react-element a{
    width: 100%;
    height: 100%;
    /* padding: 7px; */
    overflow: hidden;
    transition: all 0.3s ease-out;
    position: relative;
}
.mfa-gallery .lg-react-element a img {
    width: 100%;
    height: 100%;
    object-fit: revert;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
.mfa-gallery .lg-react-element a i {
    display: flex;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.5);
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    color: #fff;
    font-size: 28px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: all 0.3s ease-out;

}
.mfa-gallery .lg-react-element a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    transform: scale(0.5);
    border-radius: 22px;
    border-radius: 400px;
    z-index: 1;
    transition: all 0.3s ease-out;
}
.mfa-gallery .lg-react-element a:hover::before {
    opacity: 1;
    visibility: visible;
    border-radius: 0;
    transform: scale(1);
}
.mfa-gallery .lg-react-element a:hover i {
    opacity: 1;
    visibility: visible;
    border-radius: 0;
    transform: scale(1);
}
@media screen and (min-width: 768px) and (max-width: 1023px){
    .mfa-gallery .lg-react-element {
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width: 767px){
    .mfa-gallery .lg-react-element {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}