.about-slide[lang=false] {
    direction: ltr;
    /* background-color: #000; */
}
.about-slide .content-box{
    position: relative;
        max-width: 720px;
        width: 100%;
        float: right;
        z-index: 2;
        margin: 0 auto;
    }
    
    .about-slide .sec-title{
        margin-bottom:40px;
    }
    .about-slide .sec-title h2 {
        text-align: right;
    }
    .about-slide[lang=false] .sec-title h2 {
        text-align: left;
    }
    .about-slide .owl-carousel {
        direction: ltr
    }
    .about-slide .text{
        position:relative;
    }
    
    .about-slide .text p{
        position:relative;
        color: #000;
        font-size: 16px;
        line-height: 1.7em;
        font-weight: 500;
        
    }

    .about-slide .image-column .image{
        width: 100%;
            /* display: none; */
            height: 100%;
            border-radius: 20px;
        /*    border: 2px solid #5a94da;*/
        
        }
        
        .about-slide .image .image img{width: 100%;height: 100%;border-radius: 20px;}
        .about-slide .image img {
            width: 100%;
            height: 260px;
            object-fit: cover;
            border-radius: 15px;
          }
          
          @media only screen and (min-width:768px) {
            .about-slide .image img {
              height: 440px
            }
          }
          
          @media only screen and (min-width:992px) {
            .about-slide .image img {
              height: 500px
            }
          }
          
          @media only screen and (min-width:1200px) {
            .about-slide .image img {
              height: 400px;
            }
          }
          .about-slide .swiper-slide .image::after{
            content: '';
            background: transparent;          }