/**************service-details****************/

.service_details {
    padding: 70px 0;
    position: relative;
}

.service_details[lang=false] {
    direction: ltr;
}

.service_details .section_title {
    margin-bottom: 30px;
    color: #0c374c;
}
.service_details .text-p p{
    font-size: 25px;
    color: #5a94da;
        font-weight: 600;
}
.service_details_wraper p {
        font-weight: 600;
}
.service_details .contact_form input,
.service_details .contact_form textarea {
    width: 100%;
    margin-bottom: 30px;
    background: #F5F5F5;
    border: 1px solid transparent;
    text-indent: 15px;
    transition: .3s linear;
}

.service_details .contact_form input:focus,
.service_details .contact_form textarea:focus {
    border-color: #44A6F0;
}

.service_details .contact_form input {
    height: 58px;
}

.service_details .contact_form textarea {
    height: 164px;
    padding-top: 10px;
    resize: none;
}
.service_details .box-img img{
    width: 170px;
    height: 145px;
}
.service_details .nice-select:hover {
    border-color: transparent;
}

.service_details .nice-select:active,
.service_details .nice-select.open,
.service_details .nice-select:focus {
    border-color: #2B79C3;
}
.service_details form{
        padding: 30px;
        background: linear-gradient(to right, #e5e5e5 0%, #e5e5e5 100%);
        border-radius: 15px;
}

.service_details .nice-select {
    border: 1px solid transparent;
    background: #F5F5F5;
    width: 100%;
    height: 58px;
    line-height: 58px;
    transition: .3s linear;
    margin-bottom: 30px;
        padding-right: 18px;
    padding-left: 30px;
}
.service_details .nice-select span{
    float: right;
}
.service_details .nice-select:after {
    left: 12px;
    right: auto;
}
.service_details .nice-select ul.list {
    width: 100%;
}
.service_details .nice-select ul.list li{
    text-align: right;
}
.service_details .calender {
    position: relative;
    z-index: 1;
    width: 100%;
    display: inline-block;
    margin-bottom: 30px;
    height: 58px;
}

.service_details .service_details_wraper li {
    position: relative;
    padding-right: 30px;
    margin-bottom: 10px;
}
.service_details .service_details_wraper li>i {
    position: absolute;
    right: 0;
    top: 0px;
    font-size: 18px;
    color: #44a6f0;
}
/*
.service_details .calender:after {
    position: absolute;
    content: "\f073";
    left: 14px;
    top: 50%;
    font: normal normal normal 16px/1 'Material-Design-Iconic-Font';
    transform: translatey(-50%);
}
*/

.service_details .service_details_wraper img {
    margin-bottom: 60px;
    width: 100%;
    box-shadow: 0 0 40px -26px;
    object-fit: cover;
    border-radius: 30px;
    height: 100%;
}

.service_details .service_details_wraper h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 34px;
    font-style: italic;
    margin-bottom: 10px;
}

.service_details .service_quote {
    text-align: center;
    background: #F8F8F8;
    padding: 23px 2px;
}

.service_details .service_quote strong {
    font-weight: 400;
}

.service_details .service_quote i {
    font-size: 25px;
    color: #44a6f0;
}

.service_details .service_list_items li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
}

.service_details .service_list_items li>i {
    position: absolute;
    left: 0;
    top: 5px;
    font-size: 18px;
    color: #44a6f0;
}

.service_details .more_service_area a.single_service {
        
        display: inline-block;
    text-align: center;
    margin-bottom: 30px;
    color: #565656;
    padding: 20px 0 30px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
}


.service_details .more_service_area .service_icon {
    width: 70px;
    height: 70px;
    display: inline-block;
    transition: .3s cubic-bezier(0.32, 0.89, 1, 1.47);
    margin-bottom: 5px;
}

.service_details .more_service_area .single_service:hover .service_icon {
    transform: translatex(5px);
}

.service_details .more_service_area .single_service h4 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
}
.service_details .more_service_area {
    margin-top: 60px;
}


.service_details .more_service_area h1 {
    margin-bottom: 30px;
}

.service_details a.single_service {
    width: 100%;
}
.service_details .custom_btn {
    color: #fff;
    background: #009682;
    padding: 15px 30px;
    transition: .3s linear;
    position: relative;
    z-index: 1;
    border: none;
    overflow: hidden;
    text-align: center;
    min-width: 180px;
}

.service_details .image-layer {
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}
.service_details .image-layer {
position: absolute;
left: 0;
top: 0;
width: 525px;
height: 670px;
opacity: .3;
}
.service_details .ask ul li {
    list-style: decimal;
    padding-right: 0;
}
.ask ul li:last-child{
    display: none;
}
.service_details[lang=false] .ask ul li{
    direction: ltr;
}